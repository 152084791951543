import axios from "@/plugins/Axios";

const model = "profiles";

function all() {
  return axios.get(`${process.env.VUE_APP_API_URL}/${model}`);
}

function find(id) {
  return axios.get(`${process.env.VUE_APP_API_URL}/${model}/${id}`);
}

function create(state) {
  return axios.post(`${process.env.VUE_APP_API_URL}/${model}`, state);
}

function update(id, state) {
  return axios.put(`${process.env.VUE_APP_API_URL}/${model}/${id}`, state);
}

function remove(id) {
  return axios.delete(`${process.env.VUE_APP_API_URL}/${model}/${id}`);
}

function addMarcoLegalGeneral(id, marcoLegalGeneralId) {
  return axios.post(
    `${process.env.VUE_APP_API_URL}/${model}/${id}/marcos-legales-generales`,
    { marco_legal_general_id: marcoLegalGeneralId }
  );
}

function removeMarcoLegalGeneral(id, marcoLegalGeneralId) {
  return axios.delete(
    `${process.env.VUE_APP_API_URL}/${model}/${id}/marcos-legales-generales/${marcoLegalGeneralId}`
  );
}

export {
  all,
  find,
  create,
  update,
  remove,
  addMarcoLegalGeneral,
  removeMarcoLegalGeneral,
};
